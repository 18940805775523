/* Custom Variables */



$primary: #4AAAD3; // Bleu logo
$secondary: #B9D63F; // Vert logo clair
$success: #B0CC3D; // Vert logo plus foncé
$info: #c0afb2; // Beige très foncé
$warning: #78C0E0;
$danger: #FFBF00;
/*$light: #f8f9fa;*/ // Gris très clair
$light : #F5F5F5; // grey 100
/*$dark: #28190E; */ // Bistre
$dark: #616161; // grey 700

$theme-colors: (

    primary: $primary,   
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning, 
    danger: $danger,
    light: $light,
    dark: $dark,
);

$font-size-base: 0.9rem;
$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.2;
$h3-font-size: $font-size-base * 1;
$display3-size: $font-size-base * 2;
$display4-size: $font-size-base * 0.9;
$lead-font-size: $font-size-base * 1.6;

/*
[class*="col-"] {
  margin-bottom: 30px;
}

.content {
  height: 100%;
}
*/